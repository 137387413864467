/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useAppState } from "@state/state";
// import { use404PageData } from "@staticQueries";
import * as DynamicQueries from "@dynamicQueries";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Hero } from "@organisms";
import { Container, Text, Button } from "@atoms";
import { ImageRight, Mandala } from "@molecules";
import { useErrorData } from "@staticQueries";

const data = {
  hero: {
    heading: "404 – Doesn’t Ring a Bell",
    content: "We couldn’t find what you were looking for.",
  },
  content: {
    heading: "Namasté ",
    content:
      "The websearch has failed. But no one fails on the Path to Liberation Please go back, or click on one of the links above to help you find your path.",
    image: {
      url:
        "https://s3.us-west-1.amazonaws.com/hanuman-uploads/uploads/bell-wide.jpg",
      width: 1100,
      height: 768,
    },
    links: [
      {
        text: "Visit the Temple",
        url: "/",
      },
      {
        text: "Teachings",
        url: "/",
      },
      {
        text: "Upcoming Events",
        url: "/",
      },
    ],
  },
  newsletter: {
    content:
      "Sign up for the Temple newsletter for event, teachings, and community news updates.",
    field: {
      type: "email",
      placeholder: "email",
      errorMessage: "There was an error with your input",
    },
    button: {
      text: "Submit",
    },
  },
};

const NotFoundPage = ({ pageContext, location, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [{ layout }, dispatch] = useAppState();
  const { hero, content, newsletter } = useErrorData();

  useEffect(() => {
    // set preview mode if preview data is detected
    const { "x-craft-live-preview": xCraftLivePreview } = qs.parse(
      location.search
    );
    if (xCraftLivePreview) {
      dispatch({ type: "setPreviewMode", previewMode: "loading" });
    } else {
      setLoaded(true);
    }
  }, []);

  if (layout.previewMode) {
    // Use the appropriate page query based on the url
    // Note we need to manually set a `pageContext` prop and supply
    // it with a slug which we're grabbing from the last segment of the url.
    // This is used in the preview query to fetch the appropriate entry
    const split = location.pathname.split("/");
    const firstSegment = split[1];
    const lastSegment = split[split.length - 1];
    const props = { ...rest, location, pageContext: { slug: lastSegment } };

    // set up queries here for preview
    switch (firstSegment) {
      case "teachings":
        return <DynamicQueries.TeachingQuery {...props} />;
      case "events":
        return <DynamicQueries.EventQuery {...props} />;
      default:
        return <DynamicQueries.Page {...props} />;
    }
  }

  return (
    <PageContainer showNewsletter>
      <Hero {...hero} color="teal" templePattern />

      <section className="bg-offwhite py-12 sm:py-16 overflow-x-hidden overflow-y-visible">
        <Mandala position="center-left" />
        <ImageRight {...content}>
          <div className="flex flex-row space-x-4 mb-4">
            {content?.links?.map((link, i) => {
              return (
                <Button size="md" color="yellow" to={link.link?.url}>
                  <Text variant="body" className="font-serif italic text-sm">
                    {link.link?.text}
                  </Text>
                </Button>
              );
            })}
          </div>

          <Text className="text-4xl text-teal mt-6 font-serif mb-4">
            {content?.heading}
          </Text>

          <Text
            variant="body"
            className="text-bold text-black text-2xl text-teal max-w-sm w-full leading-tight md:leading-tight"
          >
            {content?.content}
          </Text>
        </ImageRight>
      </section>
    </PageContainer>
  );
};

export default NotFoundPage;
